.contact-container {

  position: relative;

  .content-wrapper{

    width: 100%;
    text-align:right;

    @media screen and (min-width: $smallScreen){

      width: 50%;
      float:left;
    }


    .contact-content {
      display: inline-block;
      text-align:left;
      padding: 40px 20px;

      @media screen and (min-width: $largeScreen) {
        max-width: 600px;
        padding: 100px 60px 20px 0px;
      }


      .title-1 {
        margin-bottom: 50px;
        @include font-size(30px);
        line-height: 40px;
        text-align: left;
      }

      .title-2 {
        @include font-size(21px);
        line-height: 34px;
        margin-bottom: 20px;
      }

      .title-3 {
        @include font-size(20px);
        margin-top: 30px;

        &.no-margin {
          margin:20px 0 0;
        }
      }

      section {
        // margin-bottom: 40px;

        p {
          // max-width: 400px;
          font-family: $mainFont;
          font-style: normal;
          @include font-size(21px);
          line-height: 38px;
          color: $black;

          span {
            text-decoration: underline;
            color: $linkColor;
          }

          &.no-margin {
            margin: 0 0 30px;
          }
        }

        .place-location {
          margin: 50px 0;
          font-family: $mainFont;
          font-style: normal;
          @include font-size(26px);
          line-height: normal;
          letter-spacing: 0.9px;
          text-transform: uppercase;
          color: $black;
        }

        .place-stage {
          margin: 30px 0 10px 0;
          font-family: $boldFont;
          font-style: normal;
          @include font-size(20px);
          line-height: normal;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $black;
        }

        .place-address {
          font-family: $mainFont;
          font-style: normal;
          @include font-size(18px);
          line-height: 23px;
          letter-spacing: 0.45px;
          color: $black;
        }
      }
    }


    .admin-content {

      background: $lightGrey;

      .admin-contact{

        margin-bottom: 20px;

        &:first-child {

          margin-top: 30px;

        }

        .no-margin{

          margin:0;
        }
      }
    }
  }


  .contact-map {
    width: 100%;
    height: 300px;
    // max-height: calc(100vh - 123px);
    @media screen and (min-width:$smallScreen){

      height: calc(100vh - 123px);
    }

    &:first-child {
      height: 300px;
      // width: 100%;

      @media screen and (min-width:$smallScreen){

        height: calc(100vh - 123px);
      }
    }

    @media screen and (min-width: $smallScreen){

      float: right;
      width: 50%;
    }

    .js-fixed {
      height: 100vh;

      > div {
        width: 100%;
        height: 100%;
      }
    }
  }

}
