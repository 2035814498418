.festival-container {
  position: relative;
  padding: 40px;
  background: $lightGrey;

  @media screen and (min-width:$smallScreen) {
    padding: 80px;
  }

  @media screen and (min-width: $mediumScreen) {
    padding: 80px 0;
  }

  @media (max-width: 499px) {
    padding: 40px 20px;
  }

  .headings {
    text-align: center;

    .strike-text {
      &:before,
      &:after {
        background: $black;
      }
    }
  }

  .festival-informations {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    @media (max-width: 499px) {
      margin-top: 40px;
    }
  }

  .information-block {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 50px;
    background: $white;

    &:nth-child(even) {
      flex-direction: row-reverse;

      @media (max-width: 899px) {
        flex-direction: column;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 899px) {
      flex-direction: column;
    }

    @media (max-width: 499px) {
      margin-bottom: 20px;
    }

    .visual,
    .content {
      width: 50%;

      @media (max-width: 899px) {
        width: 100%;
      }
    }

    .visual {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      @media (max-width: 899px) {
        min-height: 500px;
      }

      @media (max-width: 499px) {
        min-height: 200px;
      }
    }

    .content {
      padding: 80px;
      text-align: center;

       @media (max-width: 899px) {
        padding: 35px;
      }

      @media (max-width: 499px) {
        padding: 20px;
      }

      div {
        margin: 50px auto;

        @media (max-width: 499px) {
          margin: 35px auto;
        }
      }
    }
  }
}

.news-container {
  padding: 40px;
  background: $white;

  @media screen and (min-width:$smallScreen) {
    padding: 80px;
  }

  @media screen and (min-width: $mediumScreen) {
    padding: 80px 0;
  }

  @media (max-width: 499px) {
    padding: 20px;
  }
}
