/*
 *    Colors
 *    ------
 */

$colorAccent1       : #353A48;
$white              : #fff;
$black              : #262724;
$lightGrey          : #F4F4F4;
$grey               : #DBDBDB;
$blue               : #324D8F;
$red                : #E91D23;
$green              : #4BB543;

$linkColor          : #5E72A8;
$lightText          : #4C535B;



/*
 *    Fonts
 *    -----
 */
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');


@font-face {
  font-family: NeutraBold;
  src: url('../fonts/NeutraBold.eot') format('eot'), url('../fonts/NeutraBold.woff') format('woff'), url('../fonts/NeutraBold.otf');
  font-weight: normal;
}

@font-face {
  font-family: NeutraBook;
  src: url('../fonts/NeutraBook.eot') format('eot'), url('../fonts/NeutraBook.woff') format('woff'), url('../fonts/NeutraBook.otf');
  font-weight: normal;
}


@font-face {
  font-family: NeutraDemi;
  src: url('../fonts/NeutraDemi.eot') format('eot'), url('../fonts/NeutraDemi.woff') format('woff'), url('../fonts/NeutraDemi.otf');
  font-weight: normal;
}


$mainFont     : 'NeutraBook', sans-serif;
$boldFont     : 'NeutraBold', sans-serif;
$demiFont     : 'NeutraDemi', sans-serif;
$latoFont     : 'Lato', sans-serif;

/*
 *    Breakpoints
 *    -----------
 */

$exTinyScreen       : 320px;
$tinyScreen         : 480px;
$smallScreen        : 768px;
$mediumScreen       : 1024px;
$largeScreen        : 1200px;
$exLargeScreen      : 1350px;
