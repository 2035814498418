.show-container {
  background: $white;

  .artist {
    @include font-size(20px);
    text-transform: none;
    margin:5px 0 0;
  }

  .show-name {
    margin: 0 0 20px;

    &.title-3 {
      margin-bottom: 0;
    }
  }

  .show-stage {
    margin: 20px 0 0;
    font-family: $mainFont;
    font-style: normal;
    font-weight: normal;
    @include font-size(18px);
    line-height: 20px;
    letter-spacing: 0.45px;
  }

  .show-location {
    margin: 10px 0 0;
    font-family: $mainFont;
    font-style: normal;
    font-weight: normal;
    @include font-size(18px);
    line-height: 20px;
    letter-spacing: 0.45px;
    color: #353A48;
    opacity: 0.6;
  }

  .show-name,
  .show-summary {
    font-size: 18px;
    line-height: 24px;
  }

  .show-details {
    .show-status {
      font-size: 14px;
      text-transform: uppercase;
      margin: 0;
      font-weight: bolder;

    }

    .show-price {
      margin: 5px 0 20px;
      font-size: 18px;
      text-transform: uppercase;

      span {
        color: $linkColor;
      }
    }

    .show-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-family: $boldFont;
        text-transform: uppercase;
        color: #48B548;
        font-size: 15px;
        line-height: 1.2;
      }
    }

    .button {
      text-align: center;

      @media screen and (min-width: $smallScreen) {
        width: 105px;
      }

      &:last-of-type {
        margin: 0;
      }
    }

    &.sold-out,
    &.postponed,
    &.cancelled {
      .show-status {
        color: $red;
      }

      .button {
        &.more-details {
          width: 100% !important;
          margin-right: 0 !important;
        }
      }
    }

    &.extra {
      .show-status {
        color: $black;
      }
    }
  }

  .show-category {
    height: 38px;
    width: 38px;
    z-index: 1;
    pointer-events: auto;

    &.cat-all {
      background-image: url('../images/categories/all.svg');
    }

    &.cat-humour {
      background-image: url('../images/categories/humour.svg');
    }

    &.cat-music {
      background-image: url('../images/categories/music.svg');
    }

    &.cat-theater {
      background-image: url('../images/categories/theater.svg');
    }

    &.cat-dance {
      background-image: url('../images/categories/dance.svg');
    }

    &.cat-kids {
      background-image: url('../images/categories/kids.svg');
    }

    &.cat-other {
      background-image: url('../images/categories/others.svg');
    }
  }

  .all-card {
    border: 2px dotted $colorAccent1;
    padding: 60px 25px;
    background: $white;
    position: relative;

    @media screen and (min-width: $smallScreen) {
      padding: 110px 58px;
    }

    &:hover {
      a {
        color: darken($linkColor, 7%);
      }
    }

    .link-wrapper {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
      left: 0;
    }

    a {
      display: block;
      color: $linkColor;
      text-decoration: underline;
      @include font-size(30px);
      line-height: 34px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .loop-picto {
      display: block;
      width: 69px;
      height: 33px;
      margin: 30px auto 0;
      background: url('../images/see-all.svg') no-repeat center;
    }
  }

//INDIVIDUAL CARDS


&.cards-holder {
  padding: 40px 20px;
  position: relative;
  text-align: center;

  @media (min-width: $smallScreen) {
    padding: 100px;
  }

  @media (max-width: $smallScreen) {
    padding: 40px;
  }

  .headings {
    margin-bottom: 100px;

    @media (max-width: $smallScreen) {
      margin-bottom: 40px;
    }

    .strike-text {
      &:before,
      &:after {
        background: $black;
      }
    }

    a {
      display: block;
      font-size: 18px;
      margin-top: 20px;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }

  .card-wrapper {
    @media (min-width: $smallScreen) {
      padding-left: 20px;
    }

    @media (max-width: $smallScreen) {
      margin-bottom: 25px;
    }
  }

  .button {
    margin-top: 100px;

    @media (max-width: 499px) {
      margin-top: 50px;
    }
  }

  .show-card {
    position: relative;
    min-height: 635px;
    height: 100%;
    border: 2px dotted $colorAccent1;
    text-align: left;

    .img-wrapper{
      display: block;
      overflow: hidden;
      position: relative;

      .show-img {
        width: 100%;
        transform: scale(1, 1);
        transition: all 1s cubic-bezier(0, 0, 0.175, 1);
      }

      @media screen and (min-width: $mediumScreen) {
        &:hover {
          .show-img {
            transform: scale(1.1, 1.1);
          }
        }
      }
    }

    .show-date {
      position: absolute;
      top: auto;
      left: 0%;
      bottom: 0;
      z-index: 1;
      display: block;
      margin: 0;
      padding: 10px;
      color: #fff;
      font-family: $latoFont;
      font-weight: 400;
      text-transform: uppercase;
      background: $black;

      &.sold-out-date {
        bottom: 42px;
      }
    }

    .sold-out-wrap {
      width: 100%;
      position: absolute;
      top: auto;
      left: 0%;
      bottom: 0;
      z-index: 1;
      display: block;
      margin: 0;
      padding: 10px;
      color: #fff;
      font-family: $latoFont;
      font-weight: 400;
      text-transform: uppercase;
      background: #f21647;
    }

    .show-hour {
      position: absolute;
      top: 60px;
      left: -20px;
      z-index: 1;
      display: block;
      margin: 0;
      padding: 10px;
      color: #fff;
      font-family: $latoFont;
      font-weight: 400;
      text-transform: uppercase;
      background: $colorAccent1;
    }

    .show-category {
      position: absolute;
      top: 60px;
      right: 20px;
    }

    .show-info {
      padding: 15px 7.2% 60px;
    }

    .show-access-info {
      margin: 0 0 5px;

      &:last-of-type {
        margin: 0 0 55px;
      }
    }

    .show-details {
      padding: 0 7.2% 15px;
      position: absolute;
      bottom: 0;
      width: 100%;

      .show-price {
        margin-bottom: 15px;

        span {
          font-family: $latoFont;
        }
      }

      .button {
        font-size: 14px;
        width: calc(50% - 6px);
        max-width: 130px;
      }
    }
  }

  //CLUB CARDS

  &.club-shows {
    background: #000;
    text-align: center;
    position: relative;

    .headings {
      margin-bottom: 40px;

      .title-1 {
        color: $white;
      }
    }

    .show-card {
      background: $white;
      color: $black;

      .club-logo {
        position: absolute;
        display: block;
        width: 75px;
        height: 40px;
        top: 70px;
        left: 0;
        background: url('../images/le-club.jpg') no-repeat;
        background-size: cover;
        opacity: 0.95;
        z-index: 1;

        @media screen and (min-width: $smallScreen) {
          left: -20px;
          opacity: 1;
        }
      }

    }

  }

  //LISTING CARDS


  &.show-listing {
    &:after {
      content:"";
      display:block;
      clear:both;
    }

    @media screen and (min-width: 768px) {
      padding: 40px;

    }

    @media screen and (min-width: 1457px) {
      padding: 80px 80px 50px;

    }

    .card-wrapper {
      width: 100%;

      &:last-of-type {
        margin-right: 0;
      }

      @media screen and (min-width: $smallScreen) {
        margin-bottom: 35px;
        margin-right: 35px;
      }

      @media screen and (min-width: $smallScreen) and (max-width: 1200px) {
        margin-bottom: 35px;
        margin-right: 35px;
        width: calc((100% - (35px * 2)) / 2);
      }

      @media screen and (min-width: 1201px) {
        width: calc((100% - (35px * 3)) / 3);
      }

      // @media screen and (min-width: 1791px) {
      //   width: calc((100% - (35px * 4)) / 4);
      // }

      .date-card {
        position: relative;
        min-height: 635px;
        padding: 0px 57px;
        background: $colorAccent1;

        .card-month {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          padding: 0px 57px;
          margin: 0;
          font-family: $mainFont;
          font-size: 40px;
          text-transform: uppercase;
          letter-spacing: 2px;
          line-height: 140%;
          color: $white;
          transform: translate(-50%, -50%);

          @media screen and (min-width: $mediumScreen) and (max-width: $largeScreen) {
            @include font-size(60px);
            line-height: 64px;
          }

          &:before,
          &:after {
            content: '';
            display: block;
            width: 50%;
            height: 3px;
            margin: auto;
            background: $white;
          }

          &:before {
            margin-bottom: 30px;
          }

          &:after {
            margin-top: 30px;
          }
        }
      }

    }

    .programmation-row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      @media screen and (max-width: $smallScreen) {
        flex-direction: column;
      }

      .programmation-location {
        width: 100%;

        @media screen and (min-width: $smallScreen) and (max-width: 1200px) {
          width: calc((100% - (35px * 2)) / 2);

        }

        @media screen and (min-width: 1201px) {
          width: calc((100% - (35px * 3)) / 3);
        }

        @media screen and (min-width: 1440px) {
          max-width: 300px;
          width: 100%;
          height: 525px;
          margin-right: 35px;
        }

        @media screen and (min-width: 1791px) {
          width: calc((100% - (35px * 4)) / 4);
        }

        .card-wrapper{
          width: 100%;
          height: 100%;
          padding-left: 0px;
        }
      }

      .programmation-shows {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
      }
    }
  }
}
}

//CATEGORIES

.cat-holder {

  border: none;
  background-color: transparent;
  width: 38px;
  height: 38px;
  background-repeat: no-repeat;
  background-position: 0 0;
  z-index: 1;
  cursor: pointer;

  &.current-cat, &.checked {

    background-position: 0 -39px;
    opacity: 1;
  }

  &.cat-all {

    background-image: url('../images/categories/all.svg');
  }
  &.cat-humour {

    background-image: url('../images/categories/humour.svg');
  }
  &.cat-music {

    background-image: url('../images/categories/music.svg');
  }
  &.cat-theater {

    background-image: url('../images/categories/theater.svg');
  }
  &.cat-dance {

    background-image: url('../images/categories/dance.svg');
  }
  &.cat-kids {

    background-image: url('../images/categories/kids.svg');
  }
  &.cat-other {

    background-image: url('../images/categories/others.svg');
  }
}

.no-events-wrap {
  width: 100%;

  h2 {
    font-size: 30px;
  }

  p {
    font-size: 24px;
  }
}