.facebook-footer{

  background: $blue;
  color: $white;
  text-align:center;
  padding: 30px 0;

  .like-wrapper{

    display: table;
    margin:auto;

    @media screen and (max-width:$smallScreen){

      padding: 0 20px;
    }

    h3, .fb-like{

      display: table-cell;
      vertical-align: middle;
    }

    .fb-like{

      padding-left: 20px;
    }
  }
}
