  .hero-container{

    background-image: url('../images/background-hero-big.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    padding: 40px 0 100px;
    overflow: hidden;

    .hero-title {
      text-align: center;
      color: $white;
    }

    @media screen and (min-width: $mediumScreen) {
      overflow:hidden;
      padding: 105px 0 85px;
    }

    .hero-text {
      text-align: center;
      color: $white;
    }

    .hero-contents {
      max-height: 1000px;
      overflow: hidden;

      @media screen and (min-width: $mediumScreen) {
        padding: 20px 0 20px 20px;
        max-height: 500px;
      }

      @media (max-width: $smallScreen) {
        overflow: initial;
        margin: 40px auto 0;
      }

      .slick-list {
        overflow: visible;
      }

      .hero-slides {
        position:relative;
        pointer-events: none;

        .js-featured {
          width: calc(100% - 65px);
        }

        .hero-img-wrapper {
          position:relative;

          .img-wrapper {
            position: relative;
            display: inline-block;
            overflow:hidden;
            pointer-events: auto;
            border: 2px dotted $white;
            max-height: 458px;

            .hero-img{

              width: 100%;

              @media screen and (min-width: $mediumScreen){

                border-right: 0;
                max-width: 740px;
                transform: scale(1, 1);
                transition: all 1s cubic-bezier(0, 0, 0.175, 1);
              }
            }

            &:hover{

              .hero-img{

                transform: scale(1.1,1.1);
              }
            }
          }


          .show-date{

            background: rgba($black, 0.6);
            padding: 10px;
            position: absolute;
            display: block;
            top: 20px;
            left: 0;
            color: $white;
            text-transform: uppercase;
            margin: 0;
            z-index: 1;

            @media screen and (min-width: $largeScreen){

              left: -20px;
              background: $black;
            }
          }

        }

        .hero-info {
          position: relative;
          background: $white;
          padding: 20px;
          margin: -60px 20px 0;

          @media screen and (min-width: $mediumScreen) {
            position:absolute;
            top:-18px;
            right:60px;
            width:100%;
            max-width:410px;
            height: 495px;
            padding: 40px;
            margin:0;
          }

          .title-1 {
            margin-bottom: 15px;

            @media screen and (min-width: 480px) {
              margin-bottom: 30px;
            }
          }

          .show-category {
            position: absolute;
            top: 2px;
            right:0;

            @media screen and (min-width: $mediumScreen) {
              bottom: 10px;
              right: 20px;
              top: auto;
            }

            @media (max-width: $smallScreen) {
              top: 0;
              right: 20px;
              bottom: auto;
            }
          }

          .artist {
            font-family: $mainFont;
          }

          .show-stage {
            width: calc(100% - 40px);
            margin-bottom: 20px;
            font-family: $boldFont;
            font-style: normal;
            @include font-size(18px);
            line-height: 24px;
            letter-spacing: 0.45px;
            color: $black;
          }

          .show-details {
            position:relative;
            bottom:0;
            margin-top: 40px;

            @media screen and (min-width: $mediumScreen) {
              position: absolute;
              bottom:20px;
              margin-top: 78px;
              width: calc(100% - 40px);
            }

            .button {
              pointer-events: auto;
            }
          }

          .show-status {
            &.postponed, &.sold-out {
              color: $red;
            }
          }
        }

      }
    }

    .hero-nav {
      position: absolute;
      bottom: -36px;
      background: white;
      height: 60px;
      right: 40px;
      width: calc(100% - 80px);

      @media screen and (min-width: 480px) {
        bottom: -60px;
      }

      @media screen and (min-width: $mediumScreen) {
        top: 20px;
        right:0;
        height: 100%;
        max-height: 460px;
        width: auto;
      }

      .next-arrow, .prev-arrow {
        float: right;
        position: relative;
        width: 50%;
        height: 100%;
        cursor: pointer;
        box-shadow: inset 0px 15px 15px -15px rgba(0,0,0,0.22);
        transition: background 0.3s ease-in-out;

        @media screen and (min-width: $mediumScreen) {
          float: none;
          height: 50%;
          width: 60px;
          box-shadow: inset 15px 0px 15px -15px rgba(0,0,0,0.22);
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 60px;
          background: url('../images/arrow.svg') no-repeat center;

          @media screen and (min-width: $mediumScreen) {
            height: 30px;
            left: 20px;
            top: 94px;
            width: 16px;
          }
        }
      }

      .next-arrow {
        background: $white;

        &:hover {
          background: darken($white, 10%);
        }

      }

      .prev-arrow {
        background: $lightGrey;

        &:hover {
          background: darken($lightGrey, 10%);
        }

        &:before {
          transform: rotate(180deg);
        }
      }
    }


  //COMMON HEROES
  &.common-hero {
    height: 290px;
    margin-top: 50px;
    text-align:center;
    background-image: url('../images/background-hero-small.jpg');
    background-position: center;

    @media screen and (max-width: $mediumScreen) {
      &.generic-hero {
        height: 200px;

        @media (max-width: $smallScreen) {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0;
          padding: 40px 20px;
          background-position: 18% center;
        }

        .hero-contents {
          h1 {
            @include vertical-align();

            @media (max-width: $smallScreen) {
              top: initial;
              transform: none;
            }
          }
        }
      }
    }

    @media screen and (min-width: $smallScreen) {
      height: 328px;
    }

    @media screen and (max-width: $smallScreen) {
      margin-top: 15px;
      background-position: 18% center;

      .container {
        margin: 30px auto 0;
      }
    }
  }

  //SHOW HEROES
  &.show-hero {
    padding-bottom: 23%;
    text-align: center;
    background-image: url('/assets/images/background-hero-big.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;

    @media (max-width: $smallScreen) {
      padding-bottom: 50%;
    }

    .hero-title {
      margin-bottom: 30px;
      @include font-size(36px);
      line-height: 1.5;

      @media (max-width: 600px) {
        font-size: 24px;
      }
    }

    .show-name {
      color: $white;
      @include font-size(22px);
      margin: 22px 0;
      text-transform: none;
    }
  }
}

.temp-hero{
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5vw;

  @media only screen and (max-width: 550px) {
    margin-top: 0;
  }

  .img-wrap {
    width: 60%;
    // min-height: 60vh;
    background-image: url("/img/festival-stradivaria-2021-hero-banner.png");
    background-size: cover;
    background-position: center top; }
    @media only screen and (max-width: 550px) {
      .img-wrap {
        display: none; } }

  .data-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 40%;
    // min-height: 60vh;
    background-color: #1A1A1A;
    padding: 5vw 0; }

    @media only screen and (max-width: 550px) {
      .data-wrap {
        display: block;
        width: 100%;
        padding: 6vw 0; } }
  .data {
    text-align: center; }
  .hero-logo {
    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
    .logo-wrap {
      display: flex;
      align-items:center;
      justify-content: center;
      margin-top: 10px;
    }
  }
  .logo {
    width: 60px;
    height: auto;
    display: inline-block;
    margin-bottom: 0;
    padding: 0;
    margin: 0 5px;
    &:first-child {
      position: relative;
      top: 6px;
    }
  }
  .alexandre {
    display: none; }
    @media only screen and (max-width: 550px) {
      .alexandre {
        display: block;
        width: 100%;
        margin-bottom: 8vw;
        padding: 0 0; } }
  h1 {
    font-size: 40px;
    margin-bottom: 0;
    font-weight: 700;
    padding: 0 20px 0 20px;
    color: #fff;
  }
    @media only screen and (max-width: 1024px) {
      h1 {
        font-size: 24px; } }
    @media only screen and (max-width: 550px) {
      h1 {
        margin-bottom: 0; } }
  p {
    font-size: 40px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.5;
    padding: 0 0 3vw 0;
    color: #fff; }
    @media only screen and (max-width: 550px) {
      p {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 4vw;
        padding: 0 25px; } }
    p.small-mb {
      margin-bottom: 10px; }
    @media only screen and (max-width: 1024px) {
      p {
        font-size: 18px; } }

        .middle-text {
          padding: 0 0 0 0;

          strong {
            font-size: 30px;
            margin: 5vw 0;
            display: block;
          }
        }
}

.hero-container.home-hero {
  height: auto;
  padding: 7vw 20px;
  margin-top: 30px;
}

.hero-container.home-hero .hero-contents {
  overflow: visible;
}

.hero-container.home-hero .hero-title {
  width: 100%;
  font-size: 40px;
  line-height: 1.5;
}

.hero-container.home-hero .hero-title.strike-text:before {
  display: none;
  left: -30px;
}

.hero-container.home-hero .hero-title.strike-text:after {
  display: none;
  right: -30px;
}

.hero-container.home-hero .hero-text {
  max-width: 1020px;
  margin: 25px auto 0 auto;
  font-size: 24px;
  line-height: 1.5;
}

@media screen and (min-width: 1024px) {
  .hero-container.home-hero {
    margin-top: 70px;
  }

  .hero-container.home-hero .hero-title {
    font-size: 24px;
  }

  .hero-container.home-hero .hero-title.strike-text:before {
    display: block;
  }

  .hero-container.home-hero .hero-title.strike-text:after {
    display: block;
  }
}
