
.show-more-wrapper{

  position:relative;
  float:left;

  .show-more{

    margin-top: 0;
    margin-left: 55%;
  }
}


.show-more, .loading {

  display: inline-block;
  width: 150px;
  height: 150px;
  margin: 60px auto 0;
  background: url('../images/more-show-oval.svg') no-repeat center;
  cursor: pointer;
  text-align:center;

  @media screen and (min-width: $smallScreen){

    @include vertical-align();
  }

  .vertical-align-wrapper{

    opacity:1;
    transition: opacity 0.3s ease-in-out;
  }

  &.loading{

    animation-name: rotationAnimation;
    animation-duration: 1.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;

    .vertical-align-wrapper{

      opacity: 0;
    }
  }

  p {

    margin: 0;
    text-transform: uppercase;

    &.strike-text {

      &:before, &:after {

        height: 1px;
        width: 30%;
        display: block;
      }

      &:before {

        left: -40%;
      }

      &:after {

        right: -40%;
      }
    }
  }
}
