.show-main-info {
  position: relative;
  width: 100%;
  margin-top: -21%;

  @media (max-width: $smallScreen) {
    margin-top: -45%;
  }

  .hero-img-wrap {
    position: relative;

    .sold-out-wrap {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      display: block;
      margin: 0;
      padding: 10px;
      color: #fff;
      font-family: $latoFont;
      font-weight: 400;
      text-transform: uppercase;
      background: #f21647;
    }
  }

  .hero-img {
    border: 2px dotted $colorAccent1;
    width: 100%;
    max-height: 498px;

    @media screen and (min-width: $mediumScreen) {
      border-right: 0;
      max-width: 800px;
    }
  }

  .hero-share {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 38px;
    height: 38px;
    cursor: pointer;
  }

  .social-holder {
    position: absolute;
    top: 30px;
    left: 80px;
    overflow: hidden;
    display: flex;
    width: 0;
    max-width: 200px;
    height: 100%;
    max-height: 40px;
    transition: all 1s ease;

    &.is-active {
      width: auto;
    }
  }

  .hero-tickets {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: 20px;
    margin: -60px 20px 0;
    text-align: center;
    z-index: 1;
    background: $white;

    @media screen and (min-width: $mediumScreen) {
      position:absolute;
      top:-22px;
      right:0;
      width:410px;
      height: 538px;
      padding: 40px;
      margin:0;
    }

    @media screen and (max-width: 600px) {
      margin: 30px 20px 0;
    }

    .cta-wrap {
      color: #fff;
      border-radius: 3px;
      background-color: #48B548;
      padding: 15px 10px;
      margin-top: 20px;
      display: block;
      font-family: "NeutraBold", sans-serif;
      text-transform: uppercase;
      font-weight: normal;
      line-height: 1;
      font-size: 20px;
    }

    .title-1 {
      font-family: $mainFont;
      font-style: normal;
      @include font-size(24px);
      line-height: 38px;
      color: #353A48;
      text-align: left;
      text-transform: none;

      @media screen and (min-width: $smallScreen) {
        @include font-size(28px);
      }
    }

    .tickets-price {
      @include font-size(18px);
      text-transform: none;
      text-align: left;
      font-family: $mainFont;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #353A48;
      opacity: 0.6;
      margin-top: 20px;

      span {
        font-family: $latoFont;
        color: $linkColor;
      }

      @media screen and (min-width:$smallScreen) {
        @include font-size(20px);
      }
    }


    .gradient-overlay {
      position:absolute;
      height: 100%;
      width: 100%;
      bottom: 20px;
      left: 0;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+73,0.8+100 */
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 80%, rgba(255,255,255,0.8) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 80%,rgba(255,255,255,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 80%,rgba(255,255,255,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ccffffff',GradientType=0 ); /* IE6-9 */
      pointer-events: none;
      z-index:1;
    }

    .tickets-wrapper {
      // overflow-y: scroll;
      position:relative;
      // height: 310px;

      // @media screen and (min-width: $smallScreen) {
      //   height: 495px;
      //   height: calc(100% - 100px);
      // }

      .mCSB_dragger_bar {
        width: 8px;
        background: $colorAccent1 !important;
      }

      .mCSB_draggerRail {
        width: 8px;
        background: $lightGrey;
      }


      .tickets-holder {
        text-transform: uppercase;
        width: 100%;
        height: 78px;
        margin-top: 12px;

        &:first-of-type {
          margin-top: 0;
        }


        &.postponed {
          .tickets-info {
            .sold-out {
              margin-top: 6px;
            }
          }
        }

        .tickets-date {
          display: block;
          width: 60px;
          height: 100%;
          padding-top: 10px;
          font-size: 14px;
          line-height:18px;
          color: $white;
          background: $black;

          p {
            position:relative;
            margin: auto;
            font-family: $latoFont;

            span {
              display: block;
              font-size: 19px;
              line-height:22px;
            }
          }
        }

        .tickets-info {
          position: relative;
          background: $lightGrey;
          width: calc(100% - 60px);
          height: 100%;
          text-align: left;
          font-size: 14px;

          &.only-one {
            display: flex;
            flex-direction: column;
            justify-content:  center;
            align-items: flex-start;
          }

          &.unavailable {
            padding-top: 18px;
          }

          .tickets-hour,
          .tickets-status {
            display: block;
            margin: 14px 10px 0;
          }

          .tickets-hour {
            font-size: 18px;
            font-family: $latoFont;
          }

          .tickets-status {
            float: right;
            font-weight: 600;

            &.sold-out {
              color: $red;
            }

            &.extra {
              color: $black;
            }
          }

          .tickets-buy {
            position: absolute;
            display: block;
            bottom: 0;
            width: 100%;
            padding: 8px 10px 4px;
            margin: 0;
            background: $colorAccent1;
            color: $white;

            &:after {
              content: '';
              display: block;
              position: absolute;
              right: 10px;
              top: 0;
              height: 100%;
              width: 8px;
              background: url('../images/white-arrow.svg') no-repeat center;
              background-size: 100%;
            }
          }
        }

        &.postponed {
          .tickets-date {
            p {

              &:before {
                content: '';
                position: absolute;
                width: 2px;
                height: 100%;
                background: $colorAccent1;
                transform: rotate(-45deg);
                top: 0;
              }
            }
          }

          .tickets-status {
            float:none;

            span {
              font-family: $latoFont;
            }
          }
        }
      }

      .tickets-vip {
        display: flex;
        align-items: center;
        margin-top: 20px;

        span {
          font-family: $boldFont;
          font-style: normal;
          @include font-size(16px);
          line-height: 20px;
          letter-spacing: 0.4px;
          color: #5E72A8;
        }

        img {
          width: 14px;
          height: 14px;
          margin-left: 10px;
        }
      }
    }
  }
}


.show-main-content {

  padding:40px 20px 20px;
  background: $white;

  @media screen and (min-width: $smallScreen) {
    padding: 60px 20px 0;
  }

  @media screen and (min-width: $largeScreen) {
    padding: 60px 0 0;
  }

  .show-content {
    width: 100%;
    margin-right: 40px;
    color: #000;

    @media screen and (min-width: $smallScreen) {
      width: 65%;

      iframe {
        width: 100%;
      }
    }

    .show-title {
      font-size: 30px;
      margin:20px 0 0;

      @media screen and (min-width: $smallScreen) {
        margin:0 0 15px;
      }
    }

    p {
      margin: 20px 0;
      text-align: justify;
    }

    img {
      width: 100%;
      margin-top: 10px;
    }

    .video-wrapper {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .show-general-info {
    margin-top: 40px;
    width: 100%;

    @media screen and (min-width: $smallScreen) {
      margin-top: 0;
      width: calc(35% - 40px);
    }

    > div {
      border-bottom: 1px $black solid;
      padding: 15px 0;

      &:last-child {
        border: none;
      }
    }

    .title-3 {
      font-weight: 500;
      margin: 15px 0;
    }

    p:not(.title-3) {
      font-size: 14px;
    }

    .phone-tickets {
      padding:0 10px 15px;
      text-align:center;

      .title-3 {
        background: $lightGrey;
        padding: 18px 20px;
        margin:0 0 15px;

        span {
          color: $linkColor;
          font-family: $latoFont;
        }
      }
    }

    .show-promotions {
      text-align: center;
    }

    .show-category {
      padding: 10px 0;

      .cat-holder {
        margin-top: 10px;
        padding-left: 50px;
        padding-top: 10px;
        display: inline-block;
      }
    }
  }
}

.social-holder {
  a {
    display: inline-block;
    width: 38px;
    height: 38px;
    margin-right: 15px;
    background-image: url('../images/social-sprite.svg');
    background-repeat: no-repeat;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }

    &:last-child {
      margin-right: 0;
    }

    &.social-website {
      background-position: 0 0;
    }

    &.social-facebook {
      background-position: -53px 0;
    }

    &.social-twitter {
      background-position: -106px 0;
    }

    &.social-instagram {
      background-position: -159px 0;
    }

    &.social-snapchat {
      background-position: -212px 0;
    }

    &.social-youtube {
      background-position: -265px 0;
    }
  }
}

