.nav-arrows {
  position: absolute;
  top: 50%;
  width: 16px;
  height: 20px;
  cursor: pointer;
  background: url('../images/arrow.svg') no-repeat center;
  background-size: 70%;

  @media screen and (min-width: $smallScreen){

    background-size: 100%;
    height: 30px;
  }

  &.next-arrow{

    right: 8px;

    @media screen and (min-width: $smallScreen){

      right: 20px;
    }
  }

  &.prev-arrow{

    transform: rotate(180deg);
    left: 12px;

    @media screen and (min-width: $smallScreen){

      left: 20px;
    }
  }

  &.white-arrows{

    background: white;
    mask: url("../images/arrow.svg") no-repeat;
    mask-size: 70%;

    @media screen and (min-width: $smallScreen){

      mask-size: 100%;
    }
  }

  &.slick-disabled{

    opacity: 0.3;
  }
}

.show-container{

  .slick-wrapper {

    max-height: 1000px;
    overflow: hidden;

    @media screen and (min-width: $smallScreen) {

      max-height: 700px;
    }
  }


}

/* the parent */
.cards-holder{

  .slick-list {

    @media screen and (min-width: $smallScreen) {

      margin: 0 -20px;
    }
  }

  .card-wrapper{

    &.slick-slide {

      margin: 0 20px;
    }
  }


  &.carousel-holder{

    padding: 30px;

    @media screen and (min-width:$smallScreen){

      padding: 30px 20px;
    }
  }
}
