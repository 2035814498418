* {
  box-sizing: border-box;
  font-family: $mainFont;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.45px;
  color: $black;
  background: $white;

  .overlay-div {
    position: fixed;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.7);
    content: '';
    z-index: -1;
    transition: opacity 0.3s ease-in-out;
  }

  &.overlay {
    overflow: hidden;

    .overlay-div {
      opacity: 1;
      z-index: 99;
    }
  }
}

div,
header,
nav,
aside,
section,
article {
  margin: 0;
  padding: 0;
}

textarea,
input,
select,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1em;

  &:focus {
    outline: none;
  }
}

img {
  vertical-align: middle;
  border: none;
}

p, a, span, h1, h2, h3, h4, h5, li {
  &.neutra-bold {
    font-family: $boldFont;
  }

  &.neutra-demi {
    font-family: $demiFont;
  }

  &.lato-font {
    font-family: $latoFont;
  }
}

strong {
  font-family: $demiFont;
}

p {
  margin: 15px 0;

  &.single-space {
    margin: 0;
  }
}

a {
  border: none;
  color: $black;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover, &.current, &.yellow-link {
    color: $linkColor;
  }

  &.yellow-link {
    &:hover {
      color: darken($linkColor, 10%);
    }
  }
}

h1, h2, h3, .title-1, .title-2, .title-3 {
  font-family: $boldFont;
  text-transform: uppercase;
  font-weight: normal;
  color: $black;
  margin: 0;
}

.title-1, h1 {
  @include font-size(36px);
  letter-spacing: 0.9px;
  line-height: 28px;

  @media (max-width: 499px) {
    @include font-size(24px);
    line-height: 28px;
  }
}

.title-2, h2 {
  @include font-size(24px);

  line-height: 28px;
}

.title-3, h3 {
  @include font-size(20px);

  line-height: 24px;
}

.strike-text {
  position: relative;
  display: inline-block;
  text-align: center;
  font-family: $mainFont;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    width: 60px;
    top: 40%;
    background: $white;

    @media (max-width: 499px) {
      width: 40px;
    }
  }

  &:before {
    left: -80px;

    @media (max-width: 499px) {
      left: -50px;
    }
  }

  &:after {
    right: -80px;

    @media (max-width: 499px) {
      right: -50px;
    }
  }

  &.black {
    &:before, &:after {
      background: $black;
    }
  }

  &.white {
    &:before, &:after {
      background: $white;
    }
  }

  &.under {
    &:before {
      display: none;
    }

    &:after {
      width: 30%;
      height: 4px;
      top: auto;
      bottom: -10px;
      left: 0;
      background: $black;
    }
  }
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.centered {
  text-align: center;
}

.clearfix {
  overflow: hidden;
  clear: both;

  &.absolute-clearfix {
    overflow: visible;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.box-shadow {
  @include dropshadow;
}

.main-container {
  background: $lightGrey;
  padding-top: 50px;

  @media screen and (min-width: $mediumScreen) {
    padding-top: 123px;
  }

  &.white-bg {
    background: $white;
  }

  &.no-top-padding {
    padding-top: 0;
  }

  // a {
  //   font-family: $latoFont;
  // }
}

.vertical-align-wrapper {
  @include vertical-align;
}

.parsley-errors-list {
  padding: 0;

  li {
    list-style: none;
  }
}

.form-error-msg {
  display: block;
  height: 0;
  margin: 0;
  color: $red;
  transition: all .3s ease;
  overflow: hidden;

  &.is-active {
    height: auto;
    margin: 20px auto;;
  }
}

.form-success-msg {
  display: block;
  height: 0;
  margin: 0;
  color: $green;
  transition: all .3s ease;
  overflow: hidden;

  &.is-active {
    height: auto;
    margin: 20px auto;;
  }
}

////////////////////
//STICKY FOOTER
////////////////////

.wrapper {
  position: relative;

  @media screen and (min-width: $smallScreen) {
    min-height: 100%;
    margin: 0 auto -630px;
  }
}

@media screen and (min-width: $smallScreen) {
  footer, .push {
    height: 630px;
  }
}

////////////////////
// HEADER
////////////////////

header {
  .desktop-header {
    display: none;
    background: $white;
    position: fixed;
    z-index: 9999;
    width: 100%;

    @media screen and (min-width: $mediumScreen) {
      display: block;
    }

    .container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .top-header {
      background: $lightGrey;
      text-align: right;
      padding: 0 20px;

      @media screen and (min-width: $largeScreen) {
        padding: 0;
      }

      .top-header-menu {
        display: inline-block;
      }

      a:not(.header-facebook):not(.header-twitter):not(.header-phone):not(.lang-switcher) {
        text-transform: uppercase;
        margin-right: 20px;
        @include font-size(14px);
        font-family: $boldFont;

        &:last-of-type {
          margin-right: 0;
        }
      }

      .header-phone {
        display: inline-block;
        margin: 0;
        padding: 10px 20px;
        @include font-size(16px);
        color: $white;
        font-family: $latoFont;
        font-weight: 400;
        background: $colorAccent1;
      }

      .lang-switcher {
        font-family: $mainFont;
        font-style: normal;
        @include font-size(18px);
        line-height: 23px;
        letter-spacing: 0.45px;
        text-transform: uppercase;
        color: $colorAccent1;
      }
    }

    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;

      @media screen and (min-width: $largeScreen) {
        padding: 0;
      }

      .header-logo {
        padding: 25px 0;
        display: inline-block;

        img {
          transition: all 0.3s ease-in-out;
          max-width: 287px;
        }
      }

      .header-menu {
        transition: padding 0.3s ease-in-out;

        a {
          font-size: 16px;
          text-transform: uppercase;
          margin-right: 40px;
          position: relative;

          &:last-child {
            margin-right: 0;
          }

          &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            background: $colorAccent1;
            opacity: 0.5;
            width: 0;
            height: 4px;
            top: 25%;
            transition: width 0.35s ease-in-out;
          }

          &:before {
            left: -10px;
          }

          &:after {
            right: -10px;
          }

          &:hover, &.current {
            color: inherit;

            &:before, &:after {
              width: calc(100% + 18px);
            }
          }
        }
      }
    }

    &.fixed-state {
      .main-header {
        .header-logo {
          img {
            max-width: 180px;
          }
        }
      }
    }
  }

  .header-facebook {
    display: inline-block;
    float: right;
    margin: 7px 0 0 20px;
    height: 24px;
    width: 24px;
    background: url("../images/h-facebook.svg") no-repeat;
  }

  .header-twitter {
    display: inline-block;
    float: right;
    margin: 7px 0 0 20px;
    height: 24px;
    width: 24px;
    background: url("../images/h-twitter.svg") no-repeat;
  }

  .mobile-menu {
    display: block;
    position: fixed;
    z-index: 9999;
    width: 100%;
    background: $white;
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2);

    @media screen and (min-width: $mediumScreen) {
      display: none;
    }

    .menu-wrapper {
      padding: 10px 0;

      .header-logo {
        display: inline-block;
        padding-left: 15px;

        img {
          max-width: 160px;
        }
      }

      .header-facebook,
      .header-twitter {
        margin: 12px 10px 0px 0px;
      }

      .hamburger {
        padding: 11px 15px 0px 0px;
        float: right;
      }
    }

    .header-nav {
      display: none;

      .main-menu {
        .menu-links {
          display: block;
          padding: 20px 20px 10px;
          font-size: 20px;
          text-transform: uppercase;
          border-bottom: 1px solid $grey;

          &:first-of-type {
            box-shadow: inset 0 10px 15px -10px rgba(0, 0, 0, 0.2);
          }
        }
      }

      .sub-menu {
        background: $lightGrey;
        padding: 20px;

        .menu-links {
          display: block;
          font-size: 16px;
          text-transform: uppercase;
          margin: 10px 0;

          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin: 0;
          }
        }
      }

      .header-phone {
        display: block;
        width: 100%;
        font-size: 20px;
        background: $colorAccent1;
        margin: 0;
        padding: 15px 0;
        text-align: center;
        color: $white;
        font-family: $latoFont;
        text-transform: uppercase;
      }
    }
  }
}

////////////////////
// FOOTER
////////////////////

footer {
  background: $white;

  .footer-newsletter {
    padding: 80px 40px;
    text-align: center;
    background: $lightGrey;

    @media (max-width: 499px) {
      padding: 40px 20px;
    }

    .title-2 {
      font-family: $mainFont;
      font-style: normal;
      @include font-size(20px);
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.5px;
      color: $black;
    }

    .newsletter-form {
      margin-top: 40px;

      #mc_embed_signup_scroll {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media (max-width: 499px) {
          align-items: center;
          flex-direction: column;
        }
      }

      .newsletter-input {
        width: 100%;
        max-width: 530px;
        // margin: 0 0 20px;
        padding-bottom: 5px;
        font-size: 18px;
        border: 0;
        border-bottom: 1px solid $black;
        background: transparent;

        @media screen and (min-width: $mediumScreen) {
          margin-right: 40px;
        }

        @media (max-width: 499px) {
          margin-bottom: 25px;
        }

        &.parsley-error {
          border-bottom: 2px solid $red;
          color: $red;
        }
      }

      .newsletter-submit {
        border: 0;
        font-size: 14px;
        padding: 14px 25px 10px;
        margin: 0;
      }
    }
  }

  .footer-bottom {
    padding: 40px;
    text-align: center;

    @media screen and (min-width: $mediumScreen) {
      text-align: left;
      padding: 55px 55px 20px;
    }

    @media (max-width: 499px) {
      padding: 40px 20px;
    }

    .button {
      @media (min-width: $smallScreen) {
        position: absolute;
        bottom: 0;
        margin: 0;
      }
    }

    p, h3 {
      color: $colorAccent1;
    }

    .f-logo {
      width: 100%;
      max-width: 287px;
    }

    .title-3 {
      margin-bottom: 15px;
      font-family: $mainFont;
      font-style: normal;
      @include font-size(20px);
      line-height: 22px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .footer-address,
    .footer-contact {
      p, a, span {
        @include font-size(16px);
        line-height: 20px;
        letter-spacing: 0.4px;
      }

      strong {
        font-family: $boldFont;
      }

      span, a {
        color: $linkColor;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}


.newsletter-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  background: rgba($colorAccent1, 0.851245);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.45s ease 0s;

  &.is-active {
    opacity: 1;
    z-index: 99999;
  }

  .icon-tabler-x {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 35px;
    cursor: pointer;
  }

  .newsletter-content-wrap {
    position: relative;
    width: 90%;
    max-width: 620px;
    height: auto;
    padding: 45px 45px;
    background: #fff;
    border-radius: 6px;

    text-align: center;
  }

  .logo {
    display: inline-block;
    width: 100%;
    max-width: 135px;
    margin-bottom: 20px;
  }

  .title-2 {
    font-family: $mainFont;
    font-style: normal;
    font-weight: 600;
    @include font-size(20px);
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.5px;
    color: $black;
  }

  .text {
    width: 100%;
    max-width: 400px;
    margin: 20px auto 0 auto;
    color: rgba($colorAccent1, 0.8255);
  }

  .newsletter-form {
    margin-top: 40px;

    #mc_embed_signup_scroll {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      @media (max-width: 499px) {
        align-items: center;
        flex-direction: column;
      }
    }

    .newsletter-input {
      width: 100%;
      max-width: 530px;
      // margin: 0 0 20px;
      padding-bottom: 5px;
      font-size: 18px;
      border: 0;
      border-bottom: 1px solid $black;
      background: transparent;

      @media screen and (min-width: $mediumScreen) {
        margin-right: 40px;
      }

      @media (max-width: 499px) {
        margin-bottom: 25px;
      }

      &.parsley-error {
        border-bottom: 2px solid $red;
        color: $red;
      }
    }

    .newsletter-submit {
      border: 0;
      font-size: 14px;
      padding: 14px 25px 10px;
      margin: 0;
    }
  }
}
